import $ from 'jquery'
import React, { Component } from 'react'
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Redirect } from 'react-router-dom'
import swal from 'sweetalert'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import Dialog from '../../commons/dialog/Dialog.js'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import backendStrings from '../../lang/backendStrings.js'
import Loading from '../ui/Loading.js'

class TicketCargarPresupuesto extends Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.ajaxHandler = new AjaxHandler();

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
				id: this.props.ticket.id,
				manoDeObra: 0,
				repuestos: 0,
				impuestos: 0,
				otros: 0,
				adjunto: null,
				adicional: this.canDo('PRESUPUESTAR_ADIC') ? true : false
			},
			errors: [],
			loading: false,
			cleanDropzone: false
		};

		this.handleFormChange = this.handleFormChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
        	this.handleRemoveAdjunto = this.handleRemoveAdjunto.bind(this);

		this.formValidation = new FormValidation({
			component: this,
			validators: {
				'formData.manoDeObra': (value) => Validator.floatNunmber(value),
				'formData.repuestos': (value) => Validator.floatNunmber(value),
				'formData.adjunto': (value) => Validator.notEmpty(value)
			}
		});

		this.formValidationNoAdjunto = new FormValidation({
			component: this,
			validators: {
				'formData.manoDeObra': (value) => Validator.floatNunmber(value),
				'formData.repuestos': (value) => Validator.floatNunmber(value),
			}
		});

		this.initFileUpload();
	}

	componentDidMount() {
		this.ajaxHandler.subscribe(this);
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		this.setState({
			loading: false,
			formData: {
				id: null,
				manoDeObra: 0,
				repuestos: 0,
				impuestos: 0,
				otros: 0,
				adjunto: null,
				adicional: this.canDo('PRESUPUESTAR_ADIC') ? true : false
			}
		 }, ()=>{
			this.formValidation.validate();
			this.props.callbackSave();
		 });
	}

    componentDidUpdate(prevProps) {
        if (this.props.editPresupuesto !== prevProps.editPresupuesto) {
            let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
            formDataCopy['manoDeObra'] = this.props.editPresupuesto.manoDeObra;
            formDataCopy['repuestos'] = this.props.editPresupuesto.repuestos;
            formDataCopy['impuestos'] = this.props.editPresupuesto.impuestos;
            formDataCopy['otros'] = this.props.editPresupuesto.otros;
            formDataCopy['adjunto'] = this.props.editPresupuesto.adjunto;
            formDataCopy['idPresupuesto'] = this.props.editPresupuesto.id;
            formDataCopy['adicional'] = this.props.editPresupuesto.adicional;
            this.setState({
                formData: formDataCopy
            });
        }
        if(this.props.deletePresupuestoModal !== prevProps.deletePresupuestoModal){
            let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
            formDataCopy['manoDeObra'] = 0;
            formDataCopy['repuestos'] = 0;
            formDataCopy['impuestos'] = 0;
            formDataCopy['otros'] = 0;
            formDataCopy['idPresupuesto'] = null;
            formDataCopy['adjunto'] = null;
            formDataCopy['adicional'] = this.canDo('PRESUPUESTAR_ADIC') ? true : false;
            this.setState({
                formData: formDataCopy
            });
        }
    }

	handleFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		if (value >= 0){
			this.setState({
				formData: formDataCopy
			});
		}
	}

	handleSubmit(event) {
		let component = this;
		const adicional = this.canDo('PRESUPUESTAR_ADIC') ? true : false;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		if(!this.state.formData.idPresupuesto){
		    formDataCopy.adicional = adicional;
		}

		this.setState({
			formData: formDataCopy
		}, ()=>{
		this.ajaxHandler.fetch( '/ticket-presupuestos/ticket/' + this.props.ticket.id, {
			method: 'POST',
			body: JSON.stringify({
				...this.state.formData
			})
		}).then(response => {
			if(response.status === 400) {
				response.json()
				.then(data => {
					this.setState({
						errors: data.detalle
					});
				});
			} else {
				if (response.status === 202) {
					Dialog.alert(component.props.intl, {
						title: component.props.intl.formatMessage({ id: 'ticketAdjuntos.approved_budget.title_aprobado', defaultMessage: 'Aprobado' }),
						text: component.props.intl.formatMessage({ id: 'ticketAdjuntos.approved_budget.text_presupuesto_aprobado_comenzar_a_trabajar', defaultMessage: 'Su presupuesto fue aprobado. Puede comenzar a trabajar' }),
						icon: 'success'
					});
				}
				this.setState({
					loading: false,
					formData: {
						id: null,
						manoDeObra: 0,
						repuestos: 0,
						impuestos: 0,
						otros: 0,
						adjunto: null,
						adicional: this.canDo('PRESUPUESTAR_ADIC') ? true : false
					}
				 }, ()=>{
					this.props.callbackSave();
				 });
			}
			window.scrollTo(0,0);
		}).catch((error) => {
			this.ajaxHandler.handleError(error);
		}).finally(() => {
			this.setState({cleanDropzone: true});
			this.adjuntosDropzone.removeAllFiles(true);
			this.setState({cleanDropzone: false});
			this.setState({ loading: false });
		});
		});

		event.preventDefault();
	}

	handleCancel() {
		this.ajaxHandler.unsubscribe();
		this.setState({
			loading: false,
			formData: {
				id: null,
				manoDeObra: 0,
				repuestos: 0,
				impuestos: 0,
				otros: 0,
				adjunto: null,
				adicional: this.canDo('PRESUPUESTAR_ADIC') ? true : false
			}
		 }, ()=>{
			this.formValidation.validate();
			this.props.callbackClose();
			 //$('#cargar_presupuesto_modal').modal('toggle');
		 });
	}

    handleRemoveAdjunto(e, adjunto) {

        swal({
            title: this.props.intl.formatMessage({ id: 'ticketCargarPresupuesto.eliminar_adjunto.confirm_eliminación', defaultMessage: 'Confirma que desea eliminar el adjunto? ' }),
            text: "",
            icon: "warning",
            buttons: {
              confirm: {
                text: this.props.intl.formatMessage({ id: 'ticketCargarPresupuesto.eliminar_adjunto.confirm_aceptar', defaultMessage: 'Aceptar' }),
                value: true,
                visible: true,
                className: "btn btn-success",
                closeModal: true
              },
              cancel: {
                text: this.props.intl.formatMessage({ id: 'ticketCargarPresupuesto.eliminar_adjunto.confirm_cancelar', defaultMessage: 'Cancelar' }),
                value: null,
                visible: true,
                className: "btn btn-danger",
                closeModal: true,
              }
            }
          }).then(isConfirm => {
            if (isConfirm) {
                this.deleteAdjunto({adjunto:this.state.formData.adjunto});
            }
            swal.close();
          });

	}

    deleteAdjunto(file){
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
            formDataCopy.adjunto = null;
            this.setState({
                formData: formDataCopy
            });

            if(file.adjunto) {
                this.ajaxHandler.fetch('/ticket-presupuestos/adjunto/' + file.adjunto, {
                    method: 'DELETE'
                })
                .then(response => {
                    if (response.status !== 204) {
                        Dialog.alert(this.props.intl, {
                            title: this.props.intl.formatMessage({ id: 'ticketCargarPresupuesto.dialog_alert.information_error_al_eliminar_el_archivo.', defaultMessage: 'Error al eliminar el archivo.' })
                        });
                    }
                }).catch((error) => {
                    this.ajaxHandler.handleError(error);
                });
            }
    }

	initFileUpload() {
		let component = this;

		this.fileUploadConfig = {
			iconFiletypes: ['.pdf', '.jpg', '.png', '.gif', '.xls'],
			showFiletypeIcon: true,
			postUrl: Config.get('apiUrlBase') + '/ticket-presupuestos/adjunto'
		};

		this.fileUploadDjsConfig = {
			height: '100px',
			width: '100px',
			acceptedFiles: "application/pdf,image/jpeg,image/png,image/gif/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			addRemoveLinks: false,
			thumbnailMethod: 'crop',
			dictDefaultMessage: component.state.props.action !== 'VIEW' ? component.props.intl.formatMessage({ id: 'TicketCargarPresupuesto.add_file.label_haga_click_aqui_o', defaultMessage: 'Haga click aquí o' })+"<br>"+component.props.intl.formatMessage({ id: 'TicketCargarPresupuesto.add_file.label_arrastre_un_archivo_a_este_area', defaultMessage: ' arrastre un archivo a este área.' })+"<br>"+component.props.intl.formatMessage({ id: 'TicketCargarPresupuesto.add_file.label_tamano_maximo_archivo', defaultMessage: 'Tamaño máximo de archivo 20MB.' }) : '',
			uploadMultiple: true,
			parallelUploads: 1,
			maxFilesize: 20,
			headers: {
				"Authorization-Token": localStorage.getItem("token")
			},
			previewTemplate: `
		        <div class="dz-preview dz-image-preview">
		          <div class="dz-image">
		            <img data-dz-thumbnail src="/images/file.png" />
		          </div>
		          <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress="" style="width: 0%;"></span></div>
		          <div class="dz-success-mark">
		            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">      <title>Check</title>      <defs></defs>      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">        <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" stroke-opacity="0.198794158" stroke="#747474" fill-opacity="0.816519475" fill="#FFFFFF" sketch:type="MSShapeGroup"></path>      </g>    </svg>
		          </div>
		          <div class="dz-error-mark">
		            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">      <title>Error</title>      <defs></defs>      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">        <g id="Check-+-Oval-2" sketch:type="MSLayerGroup" stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475">          <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" sketch:type="MSShapeGroup"></path>        </g>      </g>    </svg>
		          </div>
		          <div class="dz-error-message"><span data-dz-errormessage></span></div>
		          <div class="dz-filename" style="width: 120px;"><span data-dz-name title="data-dz-name"></span></div>
		          <div class="dz-view"><a href="" target="_blank">VER</a></div>
		          ${component.state.props.action !== 'VIEW' ? '<div class="dz-remove"><a href="" data-dz-remove>'+component.props.intl.formatMessage({ id: 'ticketCargarPresupuesto.ELIMINAR', defaultMessage: 'ELIMINAR' })+'</a></div>' : ''}
		        </div>
		    `,
			init: function() {
				let dropzone = this;

				this.on('success', (file, response) => {
					file.adjunto = response.archivo;
					let formDataCopy = JSON.parse(JSON.stringify(component.state.formData));
					formDataCopy.adjunto = response.archivo;
					component.setState({
						formData: formDataCopy
					});
				});

				this.on('removedfile', (file) => {
					if(!component.state.cleanDropzone) {
						component.deleteAdjunto(file);
					}
				});

				this.on('error', (file, errormessage, response) => {
					dropzone.removeFile(file);
					if(errormessage.includes('files of this type')){
						Dialog.alert(component.props.intl, {
							title: component.props.intl.formatMessage({ id: 'ticketCargarPresupuesto.add_file.error_formato_de_archivo_invalido', defaultMessage: 'Formato de archivo inválido' })
						});
					} else if(errormessage.includes('File is too big')){
						Dialog.alert(component.props.intl, {
							title: component.props.intl.formatMessage({ id: 'ticketCargarPresupuesto.add_file.error_el_archivo_es_demasiado_grande', defaultMessage: 'No se pudo subir el archivo. El mismo supera el tamaño máximo de 20 MB' })
						});
					} else {
						Dialog.alert(component.props.intl, {
							title: component.props.intl.formatMessage({ id: 'ticketCargarPresupuesto.add_file.error_error_al_cargar_el_archivo', defaultMessage: 'Error al cargar el archivo.' })
						});
					}
				});

				this.on('addedfile', function(file) {
					if (this.files.length > 1) {
		              this.removeFile(this.files[0]);
		            }
		            $(file.previewElement).find('.dz-image').on("click", function() {
		              dropzone.hiddenFileInput.click();
		            });
					$(file.previewElement).find('.dz-view > a').on('click', (e) => {
						component.ajaxHandler.fetch('/ticket-presupuestos/adjunto/' + file.adjunto, {
							method: 'GET',
							headers: {
								'Authorization-Token': localStorage.getItem("token")
							}
						}).then(response => {
							if (response.status === 200) {
								return response.blob();
							}
						}).then(fileBlob => {
							let fileUrl = URL.createObjectURL(fileBlob);
							window.open(fileUrl);
						}).catch(() => {});

						e.preventDefault();
					});
				});

				if (component.state.props.action === 'VIEW') {
					dropzone.disable();
				}
			}
		};

		this.fileUploadHandlers = {
			init: dz => {
				this.adjuntosDropzone = dz;
			}
		};
	}

    handleViewAdjunto(e, adjunto) {
	this.ajaxHandler.fetch('/ticket-presupuestos/adjunto/' + adjunto, {
	  method: 'GET'
	}).then(response => {
	  if (response.status === 200) {
	    return response.blob();
	  }
	}).then(fileBlob => {
	  let fileUrl = URL.createObjectURL(fileBlob);
	  window.open(fileUrl);
	}).catch(() => {
	});
	e.preventDefault();
    }

	canDo(action) {
		return this.props.actions.indexOf(action) >= 0;
	}
    /**
  * Maneja los cambios en los campos del formulario, asegurándose de que los valores no sean negativos.
  * 
  * @param {object} event - El objeto de evento que desencadena la función.
  * @memberof NombreDelComponente
  * @function
  * 
  * @returns {void}
  */
    handleNonNegativeInputChange = (event) => {
        // Extraer el nombre y el valor del campo del evento
        const { name, value } = event.target;

        // Comprobar si el valor ingresado es un número válido (puede agregar más validaciones si es necesario)
        const isValidNumber = !isNaN(value);

        // Si es un número válido y no es negativo, actualizar el estado del formulario
        if (isValidNumber && parseFloat(value) >= 0) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: value === '' ? 0 : parseFloat(value),
                },
            });
        }
    };
  
  
	render() {
		//this.formValidation.validate();
		let conf = ConfigBusiness.get('tickets.presupuesto.adjuntoObligatorio');
		(conf === 'true') ? this.formValidation.validate() : this.formValidationNoAdjunto.validate();
		let formData = this.state.formData;
		let validationState = (conf === 'true')? this.formValidation.state : this.formValidationNoAdjunto.state;
		let requiredSymbol = ' *';
        
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
				<div className="row">
					<div className="col-md-12">
						<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
						<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
							<div className="form-body">
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section">
												<i className="la la-file-text"></i><FormattedMessage id="ticketCargarPresupuesto.render.budget.header_datos_generales" defaultMessage="Presupuesto"/>
												<div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="ticketCargarPresupuesto.render.budget.campos_requeridos" defaultMessage="campos requeridos"/></div>
											</h4>
											<div className="row">
												<div className="col-md-6">
													{/* Mano de Obra */}
                                                    <div className="form-group row">
                                                        <label className="col-md-6 label-control col-form-label" htmlFor="manoDeObra">
                                                            Mano de Obra:
                                                        </label>
                                                        <div className="col-md-6">
                                                            <div>
                                                                <input
                                                                    type="number"
                                                                    className="form-control text-right pr-1"
                                                                    id="manoDeObra"
                                                                    name="manoDeObra"
                                                                    placeholder="0.00"
                                                                    value={formData.manoDeObra === 0 ? '' : formData.manoDeObra}
                                                                    onChange={this.handleFormChange}
                                                                />
                                                                <div className="help-block text-danger" hidden={validationState.formData.manoDeObra.pristine || validationState.formData.manoDeObra.valid}>
                                                                    {validationState.formData.manoDeObra.message}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Repuestos */}
                                                    <div className="form-group row">
                                                        <label className="col-md-6 label-control col-form-label" htmlFor="repuestos">
                                                            Repuestos:
                                                        </label>
                                                        <div className="col-md-6">
                                                            <div>
                                                                <input
                                                                    type="number"
                                                                    className="form-control text-right pr-1"
                                                                    id="repuestos"
                                                                    name="repuestos"
                                                                    placeholder="0.00"
                                                                    value={formData.repuestos === 0 ? '' : formData.repuestos}
                                                                    onChange={this.handleFormChange}
                                                                />
                                                                <div className="help-block text-danger" hidden={validationState.formData.repuestos.pristine || validationState.formData.repuestos.valid}>
                                                                    {validationState.formData.repuestos.message}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Impuestos */}
                                                    <div className="form-group row">
                                                        <label className="col-md-6 label-control col-form-label" htmlFor="impuestos">
                                                            Impuestos:
                                                        </label>
                                                        <div className="col-md-6">
                                                            <div>
                                                                <input
                                                                    type="number"
                                                                    className="form-control text-right pr-1"
                                                                    id="impuestos"
                                                                    name="impuestos"
                                                                    placeholder="0.00"
                                                                    value={formData.impuestos === 0 ? '' : formData.impuestos}
                                                                    onChange={this.handleFormChange}
                                                                />
                                                                {/* <div className="help-block text-danger" hidden={validationState.formData.impuestos.pristine}>{validationState.formData.impuestos.message}</div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-md-6 label-control col-form-label" htmlFor="otros">
                                                            Otros:
                                                        </label>
                                                        <div className="col-md-6">
                                                            <div>
                                                                <input
                                                                    type="number"
                                                                    className="form-control text-right pr-1"
                                                                    id="otros"
                                                                    name="otros"
                                                                    placeholder="0.00"
                                                                    value={formData.otros === 0 ? '' : formData.otros}
                                                                    onChange={this.handleFormChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

													<div className="form-group row">
														<label className="col-md-6 label-control col-form-label">
															<FormattedMessage id="ticketCargarPresupuesto.render.budget.label_total" defaultMessage="Total:"/>
														</label>
														<div className="col-md-6">
															<div className="form-control-static col-form-label form-value text-right pr-2">
																{((formData.manoDeObra ? parseFloat(formData.manoDeObra) : 0) + (formData.repuestos ? parseFloat(formData.repuestos) : 0) + 
																  (formData.impuestos ? parseFloat(formData.impuestos) : 0) + (formData.otros ? parseFloat(formData.otros) : 0)).toFixed(2)}
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group row">
														<div className={(formData.idPresupuesto && formData.adjunto ? "d-flex ": "")+ "col-md-6"}>
															<label className="col-md-12 label-control col-form-label text-left">{this.props.intl.formatMessage({ id: 'ticketCargarPresupuestos.render.adjuntos.label', defaultMessage: 'Adjunto' })}{ ConfigBusiness.get('tickets.presupuesto.adjuntoObligatorio') === 'true' ? requiredSymbol : ''}:</label>
                                  {formData.idPresupuesto && formData.adjunto ?
																				<div className="d-flex">
																					<button type="button" className="action btn btn-icon text-fleet btn-sm btn-dt-grid p-0 m-0" onClick={(e) => this.handleViewAdjunto(e, formData.adjunto)} data-tooltip="tooltip" data-placement="top" title={this.props.intl.formatMessage({ id: 'ticketVerPresupuestos.render.budgets.button_title_ver_adjunto', defaultMessage: 'Ver Adjunto' })}>
																						<i className="fa fa-paperclip fa-xs"></i>
																					</button>
																					<button type="button" className="action btn btn-icon text-fleet text-danger btn-sm btn-dt-grid p-1 m-0" onClick={(e) => this.handleRemoveAdjunto(e, formData.adjunto)} title={this.props.intl.formatMessage({ id: 'ticketAdjuntos.render.adjuntos.button_title_eliminar', defaultMessage: 'Eliminar' })}>
																						<i className="fa fa-trash fa-xs"></i>
																					</button>
																				</div>
																			:
                                    		<div>
  																				<DropzoneComponent id="archivos" config={this.fileUploadConfig} eventHandlers={this.fileUploadHandlers} djsConfig={this.fileUploadDjsConfig} />
																					{ ConfigBusiness.get('tickets.presupuesto.adjuntoObligatorio') === 'true' ? (
																						<div className="help-block text-danger" hidden={ validationState.formData.adjunto.pristine
																							|| validationState.formData.adjunto.valid }>
																								{validationState.formData.adjunto.message}
																						</div>
																					) : ''}
																				</div>
                                  }
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card pull-up">
								<div className="card-content">
									<div className="card-body">
										<div className="text-cd text-right">
											{this.props.action !== 'VIEW' && (
											<button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid}>
											<i className="fa fa-check-circle"></i><FormattedMessage id="ticketCargarPresupuesto.finish_button.guardar" defaultMessage=" Guardar"/>
											</button>
											)}
											<button type="button" className="btn btn-danger" onClick={this.handleCancel}>
											<i className="fa fa-times-circle"></i><FormattedMessage id="ticketCargarPresupuesto.finish_button.cancelar" defaultMessage=" Cancelar"/>
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(TicketCargarPresupuesto);
